import React, {useEffect, useState} from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import Link from "../components/Link";
import Spacer from "../components/Spacer";
import {fetchAPI} from "../functions/fetch";
import Swal from 'sweetalert2';
import {CategoryEnum} from "./PlayerRegistration";
import Table from "../components/Table/Table";
import TableHead from "../components/Table/TableHead";
import TableBody from "../components/Table/TableBody";
import Button from "../components/Button";
import Flex from "../components/Flex";

export interface PlayerProps {
    firstname: string
    lastname: string
    email: string
    birthdate: string
    category: CategoryEnum
    club: string
}

function PlayerRecap() {
    const [players, setPlayers] = useState<PlayerProps[]>([]);
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const localStoragePlayers = localStorage.getItem('players');
        const localStorageEmail = localStorage.getItem('email');
        if (localStoragePlayers && localStorageEmail) {
            setPlayers(JSON.parse(localStoragePlayers));
            setEmail(localStorageEmail);
            Swal.close();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Unable to get data, please re-fill the form..."
            }).then(() => {
                window.location.href = '/player-registration'
            })
        }
    }, []);

    const _sendData = () => {
        setLoading(true);
        fetchAPI('/register-players', "POST", {email, players})
            .then(res => {
                setLoading(false);
                Swal.close();
                if (res.errMsg && res.errMsg.length) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.errMsg
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Your registration is now complete !',
                        html: `We just sent a confirmation email to <span style='font-weight: bold; font-size: 15px'>${email}</span>
                                <br/>(You might want to check your spam)`
                    }).then(() => {
                        const promise = Promise.resolve().then(function () {
                            localStorage.removeItem('players');
                        });
                        promise.then(() => {
                            window.location.href = "/players";
                        });
                    });
                }
            })
    }

    const _proceed = () => {
        Swal.fire({
            title: 'Is everything good ?',
            showDenyButton: true,
            denyButtonText: `No, go back`,
            confirmButtonText: 'Yes, proceed',
        }).then((result) => {
            if (result.isConfirmed) {
                _sendData();
            }
        })
    }

    useEffect(() => {
        if (loading) {
            Swal.fire({
                title: 'Please Wait !',
                html: `<img src="./loadingRocket.gif" alt="Rocket launch">`,
                showCancelButton: false,
                showConfirmButton: false
            });
        }
    }, [loading])

    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Let's recap together</Title>
            <p style={{fontSize: '15px'}}>You are about to make registrations for :</p>
            <Spacer/>
            {players.length ?
                <>
                    <Table>
                        <TableHead>
                            <tr>
                                <td>Name</td>
                                <td>Club</td>
                                <td>Category</td>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {
                                players.map((player: PlayerProps, index: number) => {
                                    return <tr key={index}>
                                        <td style={{fontSize: '16px'}}>{player.firstname} {player.lastname}</td>
                                        <td style={{fontSize: '16px'}}>{player.club}</td>
                                        <td style={{fontSize: '16px'}}>{player.category}</td>
                                    </tr>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Spacer/>
                    <Flex justifyContent='space-between'>
                        <Link href='/player-registration'>{`<== Go back`}</Link>
                        <Button onClick={_proceed}>Let's proceed !</Button>
                    </Flex>
                </>

                : <p>No players...</p>
            }

        </Layout>
    );
}

export default PlayerRecap;
