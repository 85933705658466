import React, {useEffect, useState} from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import Input from "../components/Input";
import {Size} from "../props/common";
import Table from "../components/Table/Table";
import TableHead from "../components/Table/TableHead";
import TableBody from "../components/Table/TableBody";
import styled from "styled-components";
import {grey, mediumGreen, red} from "../config/style";
import Accordion from "../components/accordion/Accordion";
import AccordionHeader from "../components/accordion/AccordionHeader";
import AccordionItem from "../components/accordion/AccordionItem";
import AccordionBody from "../components/accordion/AccordionBody";
import Link from "../components/Link";
import Spacer from "../components/Spacer";
import Flex, {FlexItem} from "../components/Flex";
import Button from "../components/Button";
import Swal from 'sweetalert2';

export enum CategoryEnum {
    OPEN = 'OPEN',
    VETERAN = 'VETERAN',
    WOMAN = 'WOMAN',
    U20 = 'U20',
    U16 = 'U16',
    U12 = 'U12',
    NONE = 'NONE'
}

interface CategoryProps {
    name: CategoryEnum
    price: 30 | 15 | 12 | 10 | 8 | 0
}

export interface PlayerProps {
    firstname: string
    lastname: string
    email: string
    birthdate: string
    category: CategoryEnum
    club: string
}

const TrStyled = styled.tr`
  cursor: pointer;
  position: relative;

  &:hover,
  &.is-active {
    background-color: ${mediumGreen};
  }

  &.is-active {
    font-weight: bold;
  }
`;

function PlayerRegistration() {
    const oneEmptyPlayer = {
        firstname: '',
        lastname: '',
        email: '',
        birthdate: '',
        category: CategoryEnum.NONE,
        club: ''
    }

    const [players, setPlayers] = useState<PlayerProps[]>([oneEmptyPlayer]);
    const [noOneActive, setNoOneActive] = useState<boolean>(false);
    const [existingData, setExistingData] = useState<boolean>(false);

    useEffect(() => {
        const localStoragePlayers = localStorage.getItem('players');
        if (localStoragePlayers) {
            setPlayers(JSON.parse(localStoragePlayers));
            setExistingData(true);
            localStorage.removeItem('players');
        }
    }, []);

    const categoriesList: CategoryProps[] = [
        {
            name: CategoryEnum.OPEN,
            price: 15
        },
        {
            name: CategoryEnum.VETERAN,
            price: 15
        },
        {
            name: CategoryEnum.WOMAN,
            price: 10
        },
        {
            name: CategoryEnum.U20,
            price: 10
        },
        {
            name: CategoryEnum.U16,
            price: 10
        },
        {
            name: CategoryEnum.U12,
            price: 0
        }
    ];

    const _stylingError = (players: any[]) => {
        let errorElement: string = "";
        players.forEach((player: any) => {
            errorElement += `<p style="font-size: 15px; font-weight: bold;">Player ${player.player + 1}</p>
                            <p>${player.message}</p>`;
        })
        return errorElement;
    }

    const _resetStyle = () => {
        const table = document.getElementById('category-Table');
        const allLines = table?.querySelectorAll('tr');
        allLines?.forEach((line: HTMLElement) => {
            line.classList.remove('is-active');
        })
    }

    const _updatePlayers = (name: string, value: string, index: number) => {
        let playersTmp: any = [...players];
        playersTmp[index][name] = value;
        setPlayers(playersTmp);
    }

    const _addPlayer = () => {
        setPlayers([...players, oneEmptyPlayer]);
        setNoOneActive(false);
    }

    const _deletePlayer = (index: number) => {
        let playersTmp: any = [...players];
        (index > 0) && playersTmp.splice(index, 1);

        setPlayers(playersTmp);
        setNoOneActive(true);
    }

    const _updateCategory = (clickedElement: HTMLElement, category: CategoryEnum, index: number) => {
        _resetStyle();
        const parentElement = clickedElement.parentElement;
        parentElement?.classList.add('is-active');
        _updatePlayers('category', category, index);
    }

    const _playerValidator = (player: PlayerProps): string => {
        let validator: string = "";
        if (player.firstname.length < 2) {
            validator += "• Firstname is too short<br/>";
        }
        if (player.lastname.length < 2) {
            validator += "• Lastname is too short<br/>";
        }
        if (player.club.length < 2) {
            validator += "• Club needs to be set<br/>";
        }
        if (!player.birthdate) {
            validator += "• Birthdate needs to be set<br/>";
        }
        if (player.category === CategoryEnum.NONE) {
            validator += "• You need to choose a category<br/>";
        }
        return validator;
    }

    const _sendData = () => {
        let errors: any[] = [];
        players.forEach((player: PlayerProps, index: number) => {
            const validator = _playerValidator(player);
            if (validator.length) {
                errors.push({player: index, message: validator + "\n"});
            }
        });

        if (errors.length) {
            Swal.fire({
                icon: 'error',
                title: 'Missing data',
                html: _stylingError(errors),
            })
        } else {
            Swal.fire({
                title: 'How can we reach you ?',
                text: "Please, give us your email address",
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Next step =>',
                showLoaderOnConfirm: true,
                preConfirm: async (email) => {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                        return await Promise.resolve().then(function () {
                            localStorage.setItem('email', email);
                            localStorage.setItem('players', JSON.stringify(players));
                        });
                    } else {
                        Swal.showValidationMessage(
                            `${email} is not a valid email`
                        )
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/player-recap";
                }
            });
        }
    }

    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Registration form for Major of Paris 2024</Title>
            <Accordion>
                {
                    players.map((player: PlayerProps, index: number) => {
                        return <AccordionItem key={index}
                                              className={(index === (players.length - 1) && !noOneActive) ? 'is-active' : ''}>
                            <AccordionHeader>
                                <>
                                    {`${index + 1}. `}
                                    {(player.firstname !== '' && player.lastname !== '') ?
                                        `${player.firstname} ${player.lastname}
                                    ${(player.category !== CategoryEnum.NONE) ? ` - ${player.category}` : ''}`
                                        : 'New player'}
                                </>
                            </AccordionHeader>
                            <AccordionBody>
                                <form>
                                    <Flex>
                                        <FlexItem>
                                            <Input label="Firstname" name="firstname" placeholder="Ex : John"
                                                   defaultValue={existingData ? player.firstname : ''}
                                                   onChange={(event: any) => _updatePlayers(event.target.name, event.target.value, index)}/>
                                            <Spacer/>
                                        </FlexItem>
                                        <FlexItem>
                                            <Input label="Lastname" name="lastname" placeholder="Ex : Doe"
                                                   defaultValue={existingData ? player.lastname : ''}
                                                   onChange={(event: any) => _updatePlayers(event.target.name, event.target.value, index)}/>
                                            <Spacer/>
                                        </FlexItem>
                                    </Flex>
                                    <Flex>
                                        <FlexItem>
                                            <Input label="Birthdate" name="birthdate" type="date"
                                                   defaultValue={existingData ? player.birthdate : ''}
                                                   onChange={(event: any) => _updatePlayers(event.target.name, event.target.value, index)}/>
                                            <Spacer/>
                                        </FlexItem>
                                        <FlexItem>
                                            <Input label="Club name" name="club"
                                                   placeholder="Ex : FTC Issy-les-Moulineaux"
                                                   defaultValue={existingData ? player.club : ''}
                                                   onChange={(event: any) => _updatePlayers(event.target.name, event.target.value, index)}/>
                                            <Spacer/>
                                        </FlexItem>
                                    </Flex>
                                    <Title size={Size.LEVEL_TWO} marginBottom={Size.LEVEL_ONE}>
                                        Select the category you want to play in
                                    </Title>
                                    <Table id='category-table'>
                                        <TableHead>
                                            <tr>
                                                <td>Category</td>
                                                <td>Price</td>
                                            </tr>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                categoriesList.map((category: CategoryProps, i: number) => {
                                                    return <TrStyled key={i}
                                                                     className={player.category === category.name ? 'is-active' : ''}
                                                                     onClick={(event) => _updateCategory((event.target as HTMLElement), category.name, index)}>
                                                        <td style={{textDecoration: 'underline'}}>
                                                            {category.name}
                                                        </td>
                                                        <td>{category.price}€</td>
                                                    </TrStyled>
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </form>
                                <Spacer/>
                                <Button color={red}
                                        onClick={() => index !== 0 && _deletePlayer(index)}
                                        disabled={index === 0}>
                                    Delete player
                                </Button>
                                <Spacer size={Size.LEVEL_ONE}/>
                            </AccordionBody>
                        </AccordionItem>
                    })
                }
            </Accordion>
            <Spacer/>
            <Flex justifyContent='space-between'>
                <Button color={grey} mobile onClick={_addPlayer}>{`Add player +`}</Button>
                <div style={{width: '100%', height: '32px'}} className='is-hidden-desktop'/>
                <Button mobile onClick={_sendData}>{`Proceed subscription ==>`}</Button>
            </Flex>

        </Layout>
    );
}

export default PlayerRegistration;
