import React from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import Link from "../components/Link";
import Spacer from "../components/Spacer";
import Container from "../components/Container";
import {Size} from "../props/common";
import Flex from "../components/Flex";
import player from "../assets/player.png";
import team from "../assets/team.png";
import Button from "../components/Button";

function Registration() {
    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Registration for Major of Paris 2024</Title>
            <Flex>
                <Container backgroundColor="#fff" padding={Size.LEVEL_THREE}>
                    <Flex flexDirection='column'>
                        <Title size={Size.LEVEL_THREE}>Player</Title>
                        <Spacer/>
                        <img width="200" src={player} alt="Single player"/>
                        <Spacer size={Size.LEVEL_FIVE}/>
                        <Button onClick={() => window.location.href = "/player-registration"}>Go to player registration form</Button>
                    </Flex>
                </Container>
                <Spacer horizontal size={Size.LEVEL_TEN}/>
                <Spacer size={Size.LEVEL_THREE}/>
                <Container backgroundColor="#fff" padding={Size.LEVEL_THREE}>
                    <Flex flexDirection='column'>
                        <Title size={Size.LEVEL_THREE}>Team</Title>
                        <Spacer/>
                        <img width="200" src={team} alt="Team"/>
                        <Spacer size={Size.LEVEL_FIVE}/>
                        <Button onClick={() => window.location.href = "/team-registration"}>Go to team registration form</Button>
                    </Flex>
                </Container>
            </Flex>
        </Layout>
    );
}

export default Registration;
