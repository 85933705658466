import React from 'react';
import styled from "styled-components";

interface LayoutProps {
    children: React.ReactElement | React.ReactElement[] | any
}

const LayoutStyled = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 32px;

  @media screen and (max-width: 475px) {
    padding: 16px;
  }
`;

const Layout = ({children}: LayoutProps) => {
    return <LayoutStyled>{children}</LayoutStyled>;
}

export default Layout;