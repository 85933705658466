import React, {useEffect, useState} from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import {Size} from "../props/common";
import {fetchAPI} from "../functions/fetch";
import Table from "../components/Table/Table";
import TableHead from "../components/Table/TableHead";
import TableBody from "../components/Table/TableBody";
import Link from "../components/Link";
import Spacer from "../components/Spacer";
import {TeamProps} from "./TeamRegistration";


function Teams() {
    const [teams, setTeams] = useState<TeamProps[]>([]);

    useEffect(() => {
        const fetchPlayers = async () => {
            const results = await fetchAPI('/teams', 'GET');
            setTeams(results);
        }
        fetchPlayers();
    }, []);

    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Major of Paris 2024</Title>
            <Title size={Size.LEVEL_TWO}>List of registered teams</Title>

            {teams.length ?
                <Table>
                    <TableHead>
                        <tr>
                            <td>Name</td>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {
                            teams.map((team: TeamProps, index: number) => {
                                return <tr key={index}>
                                    <td style={{fontSize: '16px'}}>{index + 1}. {team.name}</td>
                                </tr>
                            })
                        }
                    </TableBody>
                </Table>

                : <p>No team registered yet</p>
            }
        </Layout>
    );
}

export default Teams;
