import React from 'react';
import styled from 'styled-components';
import {green, grey, grey10, grey150, grey50} from "../config/style";
import {Size} from "../props/common";
import Spacer from "./Spacer";

interface InputProps {
    placeholder?: string
    label: string
    type?: 'text' | 'date' | 'password' | 'number'
    onChange?: any
    name?: string
    defaultValue?: string
}

interface InputFileProps {
    onChange?: any
}

const LabelStyled = styled.label`
  font-size: ${Size.LEVEL_TWO}px;
`;

const InputStyled = styled.input`
  background-color: ${grey10};
  border-radius: 8px;
  border: 1px solid ${grey50};
  padding: ${Size.LEVEL_THREE}px;
  padding-left: ${Size.LEVEL_TWO}px;
  width: 100%;
  height: ${Size.LEVEL_FIVE}px;
  box-sizing: border-box;
  color: ${grey150};
  font-weight: bold;
  outline-color: ${green};

  &::placeholder {
    color: ${grey};
  }
`;

const InputFileStyled = styled.input`
`;

export const Input = ({placeholder = "", label, type = 'text', onChange, name, defaultValue}: InputProps) => {
    return <>
        <LabelStyled>{label}</LabelStyled>
        <Spacer size={Size.LEVEL_ONE}/>
        <InputStyled placeholder={placeholder} type={type} onChange={onChange} name={name} defaultValue={defaultValue}/>
    </>;
}

export const InputFile = ({onChange}: InputFileProps) => {
    return <InputFileStyled type="file" onChange={onChange}/>;
}

export default Input