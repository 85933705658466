import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import PlayerRegistration from "../screens/PlayerRegistration";
import Home from "../screens/Home";
import Players from "../screens/Players";
import PlayerRecap from "../screens/PlayerRecap";
import Registration from "../screens/Registration";
import TeamRegistration from "../screens/TeamRegistration";
import TeamRecap from "../screens/TeamRecap";
import Teams from "../screens/Teams";

const Router = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/players" element={<Players />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/player-registration" element={<PlayerRegistration />} />
            <Route path="/team-registration" element={<TeamRegistration />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/player-recap" element={<PlayerRecap />} />
            <Route path="/team-recap" element={<TeamRecap />} />
        </Routes>
    </BrowserRouter>
}

export default Router;