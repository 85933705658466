import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";
import {green, grey30, grey75, lightGreen, lightRed, red} from "../config/style";
import Spacer from "./Spacer";

interface ButtonPros {
    size?: Size
    children?: any
    marginBottom?: Size
    borderRadius?: string
    color?: string
    onClick?: any
    disabled?: boolean
    mobile?: boolean
}

const ButtonStyled = styled.button<ButtonPros>`
  border-radius: ${props => props.borderRadius};
  padding: ${Size.LEVEL_ONE}px ${Size.LEVEL_TWO}px;
  background-color: ${props => props.color};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid ${props => props.color};
  box-sizing: border-box;
  transition: 0.13s;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    width: ${props => props.mobile ? '100%' : 'auto'};
    box-sizing: border-box;
  }

  &:hover {
    background-color: ${props => props.color === green ? lightGreen : lightRed};
    color: ${props => props.color};
  }

  &:disabled {
    cursor: no-drop;
    background-color: ${grey30};
    color: ${grey75};
    border-color: ${grey75};
  }
`;

const Button = ({
                    children,
                    size = Size.LEVEL_THREE,
                    marginBottom = Size.LEVEL_THREE,
                    borderRadius = `${Size.LEVEL_ONE}px`,
                    color = green,
                    onClick = null,
                    disabled = false,
                    mobile = false
                }: ButtonPros) => {
    return <ButtonStyled size={size} marginBottom={marginBottom} borderRadius={borderRadius}
                         color={color} onClick={onClick} disabled={disabled} mobile={mobile}>
        {children}
        {color === red && <>
            <Spacer size={Size.LEVEL_ONE} horizontal/>
            <span>✖</span>
        </>}

    </ButtonStyled>
}

export default Button;