import React from 'react';
import styled from 'styled-components';
import {TableHeadPros} from "./TableHead";
import {TableBodyProps} from "./TableBody";
import { green } from '../../config/style';

interface TablePros {
    children?: React.ReactElement<TableHeadPros & TableBodyProps>[]
    id?: string
}

const TableStyled = styled.table<TablePros>`
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;

  td {
    padding: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Table = ({children, id}: TablePros) => {
    return <div style={{border: `1px solid ${green}`, borderRadius: '8px', overflow: 'auto'}}>
        <TableStyled id={id}>
            {children}
        </TableStyled>
    </div>
}

export default Table;