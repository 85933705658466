export const yellow = '#FAC213';
export const lightYellow = '#ffedc8';
export const orange = '#F77E21';
export const lightOrange = '#ffd0ac';
export const red = '#EB1D36';
export const lightRed = '#fff1f4';
export const green = '#36AE7C';
export const lightGreen = '#ebfcf5';
export const mediumGreen = '#c0f3de';
export const grey10 = '#f5f5f5';
export const grey30 = '#e8e8e8';
export const grey50 = '#c4c4c4';
export const grey75 = '#9b9b9b';
export const grey = '#6e6e6e';
export const grey150 = '#383838';