import React from 'react';
import styled from 'styled-components';

export interface AccordionBodyProps {
    children?: React.ReactElement | React.ReactElement[]
}

const AccordionBodyStyled = styled.div<AccordionBodyProps>`
  display: none;
  padding: 8px 16px;
`;

export const AccordionBody = ({children}: AccordionBodyProps) => {
    return <AccordionBodyStyled data-accordion-body>{children}</AccordionBodyStyled>
}

export default AccordionBody
