import React from 'react';
import styled from 'styled-components';

export interface TableBodyProps {
    children?: React.ReactElement | React.ReactElement[]
}

const TableBodyStyled = styled.tbody<TableBodyProps>`
  tr {
    &:nth-child(odd) {
      background-color: #f1f1f1;
    }

    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: 0;

      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

const TableBody = ({children}: TableBodyProps) => {
    return <TableBodyStyled>
        {children}
    </TableBodyStyled>
}

export default TableBody;