import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";
import {green} from "../config/style";

interface LinkPros {
    size?: Size
    children?: any
    marginBottom?: Size
    onClick?: React.MouseEventHandler
    href?: string
    blank?: boolean
}

const LinkStyled = styled.a<LinkPros>`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
  transition: 0.1s;
  
  &:hover {
    color: ${green};
  }
`;

const Link = ({size = Size.LEVEL_THREE, children, onClick, href, blank}: LinkPros) => {
    return <LinkStyled size={size} onClick={onClick} href={href} target={blank ? '_blank' : ''}>
        {children}
    </LinkStyled>
}

export default Link;