import React from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import {Size} from "../props/common";
import Flex from "../components/Flex";
import Spacer from "../components/Spacer";
import Link from "../components/Link";
import Container from "../components/Container";
import issy from "../assets/FTC issy Rond.png";
import playerLogo from "../assets/player.png";
import teamLogo from "../assets/team.png";
import Button from "../components/Button";

function Home() {

    return (
        <Layout>
            <Flex justifyContent='center'>
                <Title size={Size.LEVEL_SIX}>Major of Paris 2024</Title>
            </Flex>
            <Flex>
                <img src={issy} alt='FTC Issy logo' width="100px"/>
            </Flex>
            <Spacer/>
            <Flex justifyContent="space-between" alignItems='flex-start'>
                <Container backgroundColor="#fff" padding={Size.LEVEL_THREE}>
                    <div id="home-content">
                        <Flex flexDirection='column' justifyContent='space-between' alignItems='stretch' height='100%'>
                            <div>
                                <p style={{fontSize: '15px'}}>
                                    Major of Paris 2024 will take place on 3rd & 4th of february 2024.
                                    Here are few information about the tournament :
                                </p>
                                <Spacer/>
                                <ul>
                                    <li style={{fontSize: '15px'}}>
                                        <span style={{textDecoration: 'underline', fontSize: '15px'}}>Address</span> :
                                        Palais Omnisports Pierre Duprès - 18/20 Rue de la Digue, 78600 Maisons-Laffitte,
                                        FRANCE
                                        (<Link href="https://maps.app.goo.gl/DMNM9QVYBJ3Bi2YH9" blank>Maps</Link>)
                                    </li>
                                    <Spacer size={Size.LEVEL_ONE}/>
                                    <li style={{fontSize: '15px'}}>
                                        <span style={{textDecoration: 'underline', fontSize: '15px'}}>Saturday</span> :
                                        <ul>
                                            <li style={{fontSize: '15px'}}>Individual event with OPEN & Categories</li>
                                            <li style={{fontSize: '15px'}}>Opening 8am & matches begin at 8:30am</li>
                                        </ul>
                                    </li>
                                    <Spacer size={Size.LEVEL_ONE}/>
                                    <li style={{fontSize: '15px'}}>
                                        <span style={{textDecoration: 'underline', fontSize: '15px'}}>Sunday</span> :
                                        <ul>
                                            <li style={{fontSize: '15px'}}>Team event</li>
                                            <li style={{fontSize: '15px'}}>Opening 8am & matches begin at 8:30am</li>
                                        </ul>
                                    </li>
                                    <Spacer size={Size.LEVEL_ONE}/>
                                    <li style={{fontSize: '15px'}}>
                                        <span style={{textDecoration: 'underline', fontSize: '15px'}}>
                                            Accommodation
                                        </span> :
                                        <p style={{fontSize: '15px'}}>
                                            We have partnered with the IBIS HOTEL, that is literally 3 minutes walk from
                                            the venue and 5 minutes from the Train Station, offering FREE car park too!
                                            Book using code <span style={{fontWeight: "bold", fontSize: '15px'}}>
                                            PK2</span> to get the unbeatable deal of
                                            <span style={{fontWeight: "bold", fontSize: '15px'}}> 75€</span> for a
                                            twin or double Room. Email:&nbsp;
                                            <Link href="mailto:h3437@accor.com">
                                                <span style={{fontSize: '15px'}}>
                                                h3437@accor.com
                                                </span>
                                            </Link>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <Spacer/>
                                <Flex justifyContent='flex-start'>
                                    <Link href='/players'>
                                        <Flex flexDirection='column'>
                                            <img src={playerLogo} alt="Player logo" width="100px"/>
                                            <Spacer/>
                                            <span>Players</span>
                                        </Flex>
                                    </Link>
                                    <Spacer horizontal size={Size.LEVEL_SIX}/>
                                    <Link href='/teams'>
                                        <Flex flexDirection='column'>
                                            <img src={teamLogo} alt="Player logo" width="100px"/>
                                            <Spacer/>
                                            <span>Teams</span>
                                        </Flex>
                                    </Link>
                                </Flex>
                                <Spacer/>
                            </div>
                            <div>
                                <Spacer size={Size.LEVEL_TWO}/>
                                <Title>We've reached maximum players limit. Contact us for more information.</Title>
                                {/*<Button mobile onClick={() => window.location.href = '/registration'}>*/}
                                {/*    <span style={{*/}
                                {/*        fontSize: '16px',*/}
                                {/*        textTransform: 'uppercase'*/}
                                {/*    }}>I want to register !</span>*/}
                                {/*</Button>*/}
                            </div>
                        </Flex>
                    </div>
                </Container>
                <Spacer horizontal size={Size.LEVEL_TEN}/>
                <div id="logo-issy">
                    <iframe
                        title="Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2620.511282072288!2d2.14824467730497!3d48.943749671343866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6617f6b91f541%3A0x5e5b356f588388df!2sPalais%20Omnisports%20Pierre%20Dupr%C3%A8s!5e0!3m2!1sfr!2sfr!4v1695678141341!5m2!1sfr!2sfr"
                        width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Flex>
        </Layout>
    );
}

export default Home;
