import React from 'react';
import './App.css';
import Router from "./config/routes";

function App() {
    return (
        <Router/>
    );
}

export default App;
