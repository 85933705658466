import React, {useEffect, useState} from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import {Size} from "../props/common";
import {PlayerProps} from "./PlayerRegistration";
import {fetchAPI} from "../functions/fetch";
import Table from "../components/Table/Table";
import TableHead from "../components/Table/TableHead";
import TableBody from "../components/Table/TableBody";
import Link from "../components/Link";
import Spacer from "../components/Spacer";

function Players() {
    const [playersByCategory, setPlayersByCategory] = useState<any>([]);

    useEffect(() => {
        const fetchPlayers = async () => {
            const results = await fetchAPI('/players', 'GET');
            setPlayersByCategory(results);
        }
        fetchPlayers();
    }, []);

    const displayPlayersByCategory = (category: string) => {
        return <Table>
            <TableHead>
                <tr>
                    <td colSpan={2}>{category}</td>
                </tr>
            </TableHead>
            <TableBody>
                {
                    playersByCategory[category]?.length && playersByCategory[category].map((player: PlayerProps, index: number) => {
                        return <tr key={index}>
                            <td style={{fontSize: '16px', width: '50%'}}>{index + 1}. {player.firstname} {player.lastname}</td>
                            <td style={{fontSize: '16px', width: '50%'}}>{player.club}</td>
                        </tr>
                    })
                }
            </TableBody>
        </Table>
    }

    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Major of Paris 2024</Title>
            <Title size={Size.LEVEL_TWO}>List of registered players</Title>

            {displayPlayersByCategory("OPEN")}
            <Spacer/>
            {displayPlayersByCategory("VETERAN")}
            <Spacer/>
            {displayPlayersByCategory("WOMAN")}
            <Spacer/>
            {displayPlayersByCategory("U20")}
            <Spacer/>
            {displayPlayersByCategory("U16")}
            <Spacer/>
            {displayPlayersByCategory("U12")}
        </Layout>
    );
}

export default Players;
