import {API} from '../config/env';

export const fetchAPI = async (url: string, method: string = "POST", body: any = {}) => {
    const apiUrl = API;

    const postQueryConf: any = {
        method: 'POST',
        headers: {
            'Accept': "application/json",
            'Content-Type': "application/json"
        },
        body: JSON.stringify(body)
    }

    switch (method) {
        case 'GET':
            return await fetch(apiUrl + url)
                .then(res => res.json())
                .then(res => res)
                .catch(err => err);
        case 'POST':
            return await fetch(apiUrl + url, postQueryConf)
                .then(res => res.json())
                .then(res => res)
                .catch(err => err);
        default:
            break;
    }
}