import React from 'react';
import styled from 'styled-components';
import {green} from "../../config/style";

export interface TableHeadPros {
    children?: React.ReactElement
}

const TableHeadStyled = styled.thead<TableHeadPros>`
  width: 100%;
  background-color: ${green};
  color: white;
  font-weight: bold;

  td {
    &:first-child {
      border-top-left-radius: 6px;
    }
    
    &:last-child {
      border-top-right-radius: 6px;
    }
  }
`;

const TableHead = ({children}: TableHeadPros) => {
    return <TableHeadStyled>
        {children}
    </TableHeadStyled>
}

export default TableHead;