import React, {useEffect, useState} from 'react';
import '../App.css';
import Layout from "../components/Layout";
import Title from "../components/Title";
import Input from "../components/Input";
import {Size} from "../props/common";
import Link from "../components/Link";
import Spacer from "../components/Spacer";
import Flex from "../components/Flex";
import Button from "../components/Button";
import Swal from 'sweetalert2';
import Container from "../components/Container";
import {grey, red} from "../config/style";

export interface TeamProps {
    name: string
}

function TeamRegistration() {
    const oneEmptyTeam: TeamProps = {
        name: ''
    }

    const [teams, setTeams] = useState<TeamProps[]>([oneEmptyTeam]);
    const [existingData, setExistingData] = useState<boolean>(false);

    useEffect(() => {
        const localStorageTeams = localStorage.getItem('teams');
        if (localStorageTeams) {
            setTeams(JSON.parse(localStorageTeams));
            setExistingData(true);
            localStorage.removeItem('teams');
        }
    }, []);

    const _stylingError = (teams: any[]) => {
        let errorElement: string = "";
        teams.forEach((team: any) => {
            errorElement += `<p style="font-size: 15px; font-weight: bold;">Team ${team.team + 1}</p>
                            <p>${team.message}</p>`;
        })
        return errorElement;
    }

    const _teamsValidator = (team: TeamProps): string => {
        let validator: string = "";
        if (team.name.length < 2) {
            validator += "• Name is too short<br/>";
        }
        return validator;
    }

    const _updateTeams = (name: string, value: string, index: number) => {
        let teamsTmp: any = [...teams];
        teamsTmp[index][name] = value;
        setTeams(teamsTmp);
    }

    const _deleteTeam = (index: number) => {
        let teamsTmp: any = [...teams];
        (index > 0) && teamsTmp.splice(index, 1);

        setTeams(teamsTmp);
    }

    const _addTeam = () => {
        setTeams([...teams, oneEmptyTeam]);
    }

    const _sendData = () => {
        let errors: any[] = [];
        teams.forEach((team: TeamProps, index: number) => {
            const validator = _teamsValidator(team);
            if (validator.length) {
                errors.push({team: index, message: validator + "\n"});
            }
        });

        if (errors.length) {
            Swal.fire({
                icon: 'error',
                title: 'Missing data',
                html: _stylingError(errors),
            })
        } else {
            Swal.fire({
                title: 'How can we reach you ?',
                text: "Please, give us your email address",
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Next step =>',
                showLoaderOnConfirm: true,
                preConfirm: async (email) => {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                        return await Promise.resolve().then(function () {
                            localStorage.setItem('email', email);
                            localStorage.setItem('teams', JSON.stringify(teams));
                        });
                    } else {
                        Swal.showValidationMessage(
                            `${email} is not a valid email`
                        )
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/team-recap";
                }
            });
        }
    };

    return (
        <Layout>
            <Link href='/'>{`<== Back home`}</Link>
            <Spacer/>
            <Title>Registration form for Major of Paris 2024</Title>
            <>
                {
                    teams.length ? teams.map((team: TeamProps, index: number) => {
                            return <div key={index}>
                                <Container backgroundColor="#fff" padding={Size.LEVEL_THREE}>
                                    <Input label={`${index + 1}. Team name`} name="name"
                                           placeholder="Ex : FTC Issy-les-Moulineaux A"
                                           defaultValue={existingData ? team.name : ''}
                                           onChange={(event: any) => _updateTeams(event.target.name, event.target.value, index)}/>
                                    <Spacer/>
                                    <Button color={red}
                                            onClick={() => index !== 0 && _deleteTeam(index)}
                                            disabled={index === 0}>
                                        Delete team
                                    </Button>
                                </Container>
                                <Spacer/>
                            </div>
                        })
                        : <p>No team</p>
                }
            </>
            <Flex justifyContent='space-between'>
                <Button mobile color={grey} onClick={_addTeam}>{`Add team +`}</Button>
                <div style={{width: '100%', height: '32px'}} className='is-hidden-desktop'/>
                <Button mobile onClick={_sendData}>{`Proceed subscription ==>`}</Button>
            </Flex>

        </Layout>
    );
}

export default TeamRegistration;
